import React from 'react'
import { Flex, Span } from './UI'
import styled from 'styled-components'

const Container = styled(Flex)`
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
`

const Label = styled(Span)`
    color: ${props => props.theme.colors.black};
    font-size: 0.75rem !important;
    margin-bottom: 0px;
`

const Error = styled(Label)`
    color: ${props => props.theme.colors.red};
`

const Input = styled.input`
    border: none;
    border-bottom: 2px solid ${props => props.theme.colors.black};
    ${props => props.theme.fontStyle.body};
    background-color: transparent;
    width: 100%;
    padding: 12px 6px;
    
    &:focus {
        outline: none;
        //outline: 1px solid ${props => props.theme.colors.lightGrey};
    }
`

const FormItem = ({name, label, placeholder, type, error, value, onChange, toggleError}) =>  {
    return (
        <Container>
            {type !== 'checkbox'
                ?
                <>
                    <Label id={name} htmlFor={name}>{label}</Label>
                    <Input id={name} name={name} type={type} placeholder={placeholder} value={value} onChange={onChange}/>
                    {toggleError && <Error>{error}</Error>}
                </> :
                <>
                    <Input id={name} name={name} type={type} value={value}/>
                    <label id={name} htmlFor={name}>{label}</label>
                </>
            }

        </Container>
    )
}

export default FormItem

import React, { useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Flex } from './UI'
import useLockBodyScroll from "../utils/useLockBodyScroll";
//import { breakpoints } from '../utils/helper'
import MenuItemMobile from "./MenuItemMobile";
//import {OutboundLink} from "gatsby-plugin-gtag";
import { Power3, gsap } from 'gsap'
import {Link, navigate} from 'gatsby'
import _ from 'lodash'
import Button from "./Button";
import {OutboundLink} from 'gatsby-plugin-gtag';

const Background = styled.div`
    width: 100%;
    height: 100%;
    top: 64px;
    left: 0;
    bottom: calc(100vh - 60px);
    background-color: ${props => props.theme.colors.white};
    position: fixed;
    z-index: 1000;
`

const Outer = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 80%;
    padding: 0 0 0 20vw;
`

const MenuLinks = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 310px;
`

/*const ExternalLink = styled(OutboundLink)`
    ${props => props.theme.fontStyle.link};
    color: ${props => props.theme.colors.darkGrey};
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 18px;

    @media ${breakpoints.tablet} {
        margin-bottom: 0;
    }
`*/

const Spacer = styled.div`
    height: 20px;
`

const CloseHelper = styled.div`
    position: absolute;
    background-color: transparent;
    width: 30px;
    height: 30px;
    top: -48px;
    left: 16px;
`

const InteralLink = styled(Link)`
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
    }
`

const MobileNav = ({location, onUpdate, onComplete}) => {
    const links = [
        {"title": "Home", "path": "/", external: false},
        {"title": "Vestigingen", "path": "/vestigingen", external: false},
        {"title": "Boek nu", "path": "https://upcarwash.simplybook.it/", external: true},
        {"title": "Over UP", "path": "/over-up", external: false},
        {"title": "Franchise", "path": "/franchise", external: false},
        {"title": "Contact", "path": "/contact", external: false}]
    const background = useRef(null)
    const isActive = (paths) => _.equals(paths, location.pathname)

    useLockBodyScroll()

    useLayoutEffect(() => {
        gsap.from(background.current, {duration: 1, x: '-=100vw', ease: Power3.easeInOut})
    }, [])


    const transitionOut= (to) =>
        to !== location.pathname ? close( () => to === navigate(to)) : close()


    const close = (callback) => {
        onUpdate()

        gsap.to(background.current, {
            duration: 1, x: '-=100vw', ease: Power3.easeInOut, onComplete: () => {
                //onComplete()
                if (!_.isUndefined(callback)) {
                    callback()
                } else {
                    onComplete()
                }
            }
        })
    }

    return (
        <Background ref={background}>
            <CloseHelper onClick={() => close()}/>
            <Outer>
                <MenuLinks>
                    {links.map((item, index) =>
                        <MenuItemMobile key={index} to={item.path} external={item.external} onClick={() => transitionOut(item.external ? '' : item.path)} active={isActive(item.path)}>{item.title}</MenuItemMobile>)}
                    <Spacer/>
                </MenuLinks>
                <OutboundLink href="https://club.upcarwash.nl/inloggen"><Button color={Button.styles.RED}>UP Club</Button></OutboundLink>
            </Outer>
        </Background>
    )
}

export default MobileNav

import React, { useRef } from 'react'
import { Link } from 'gatsby'
import {Span, Flex, Row, RightSection, LeftSection} from './UI'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-gtag'
import Facebook from '../svg/facebook.svg'
import Instagram from '../svg/instagram.svg'
import { breakpoints} from '../utils/helper'
import locationData from '../data/locations.json'
import _ from 'lodash'
import Modal from './Modal'

const StyledFooter = styled.footer`
    width: 100%;
    max-width: 1000px;
    padding: 40px 12px;
    background-color: ${props => props.theme.colors.lightGrey};
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    
    @media ${breakpoints.desktop} {
        padding: 40px 30px;
        background-color: ${props => props.theme.colors.white};
    }
    
    @media ${breakpoints.large} {
        padding: 120px 0;
        background-color: ${props => props.theme.colors.white};
    }
`

const Inner = styled(Flex)`
    flex-direction: column;
    align-items: flex-start; 
    justify-content: space-between;
    width: 100%;
    
    @media ${breakpoints.desktop} {
        flex-direction: row;
    }
    
    &.links {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 36px 0 0;
        
        @media ${breakpoints.desktop} {
            align-items: center;
        }
        
        div:first-child {
            margin-right: 18px;
        }
    }
`

const Column = styled(Flex)`
    flex-direction: column;
    margin-bottom: 30px;
    flex: 1;
    
    @media ${breakpoints.desktop} {
        margin-bottom: 0px;
    }
`

const SectionTitle = styled(Span)`
    color: ${props => props.theme.colors.red};
    margin-bottom: 12px;
    font-size: 1rem;
    white-space: pre-wrap;
    font-weight: 600;

    @media ${breakpoints.tablet} {
        font-size: 1.125rem;
    }
    
    @media ${breakpoints.desktop} {
        margin-bottom: 30px;
    }
`

const InteralLink = styled(Link)`
    ${props => props.theme.fontStyle.link};
    color: #000000;
    margin-bottom: 12px;
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
    
    line-height: 1.25rem;
    font-size: 0.75rem;
    
    @media ${breakpoints.tablet} {
        font-size: 0.875rem;
    }
`

const ExternalLink = styled(OutboundLink)`
    ${props => props.theme.fontStyle.link};
    color: #000000;
    margin-bottom: 12px;
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
    
    line-height: 1.25rem;
    font-size: 0.75rem;
    
    @media ${breakpoints.tablet} {
        font-size: 0.875rem;
    }
        
`

const FooterLink = styled(ExternalLink)`
    ${props => props.theme.fontStyle.footnote};
    margin-right: 12px;
    
    @media ${breakpoints.desktop} {
        margin-right: 12px;
    }
`

const Wrapper = styled.div`
    flex: 2;
`

const MyRow = styled(Row)`
    padding: 30px 0;
    
    @media ${breakpoints.desktop} {
        padding: 30px 0px;
    }
`

const MyLeft = styled(LeftSection)`
    flex-direction: row;
`


const Footer = () => {
    const modal = useRef()

    return (
        <StyledFooter>
            <MyRow>
                <MyLeft>
                    <Column>
                        <SectionTitle>Locaties</SectionTitle>
                        {locationData.locations.map((data, i) =>
                            <InteralLink key={`${i}-${data.city}`} to={`/vestigingen/${_.lowerCase(data.city)}`}>{data.city}</InteralLink>)}
                    </Column>
                    <Column>
                        <SectionTitle>Services</SectionTitle>
                        <ExternalLink href="https://upcarwashbooking.nl" target="_blank" rel="noopener">Boek nu</ExternalLink>
                        <ExternalLink href="https://www.x10spin.com/org/upcarwash/login" target="_blank" rel="noopener">UP Club Business</ExternalLink>
                    </Column>
                    <Column>
                        <SectionTitle>Info</SectionTitle>
                        <InteralLink to="/over-up">Over UP</InteralLink>
                        <ExternalLink href="https://upcarwash.recruitee.com/" target="_blank" rel="noopener">Werken bij</ExternalLink>
                        <InteralLink to="/franchise">Franchise</InteralLink>
                        <InteralLink to="/contact">Klantenservice</InteralLink>
                        <ExternalLink href="http://aandacht.upcarwash.nl" target="_blank" rel="noopener">FAQ</ExternalLink>
                    </Column>
                </MyLeft>
                <RightSection>
                    <Wrapper>
                        {/*<MailSignup/>*/}
                    </Wrapper>
                </RightSection>
            </MyRow>
            <Inner className="links">
                <Flex flexDirection="row">
                    <FooterLink href="https://www.facebook.com/up.carwash.nl" target="_blank" rel="noopener"><Facebook/></FooterLink>
                    <FooterLink href="https://www.instagram.com/up.carwash" target="_blank" rel="noopener"><Instagram/></FooterLink>
                    {/*<FooterLink href="https://wa.me/message/A4LNTP5P5CXVG1" target="_blank" rel="noopener"><Whatsapp/></FooterLink>*/}
                </Flex>
                <Flex flexDirection="row">
                    <FooterLink href="/privacy.pdf" target="_blank" rel="noopener">Privacy</FooterLink>
                    <FooterLink href="/algemenevoorwaarden.pdf" target="_blank" rel="noopener">Algemene voorwaarden</FooterLink>
                </Flex>
            </Inner>

            <Modal ref={modal} title="Vraag je UP Club pas opnieuw aan"/>
        </StyledFooter>
    )
}


export default Footer

import MenuItem from './MenuItem'
import Button from './Button'
import React from 'react'
import styled from 'styled-components'
import {Flex} from './UI'
import Logo from '../svg/logo.svg'
import Word from '../svg/word.svg'
import WordRed from '../svg/word-red.svg'
import { breakpoints } from '../utils/helper'
import {Link} from 'gatsby'
import _ from 'lodash'
import {OutboundLink} from 'gatsby-plugin-gtag';

const Container = styled(Flex)`
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    padding: 0 12px;    
    align-items: center;
    justify-content: flex-start;
    
    @media ${breakpoints.large} {
        padding: 0 0;
    }
    
    button {
        justify-self: flex-end;
    }
    
    a {
        color: ${props => !props.dark ? props.theme.colors.black : props.theme.colors.white};
    }
`

const LogoContainer = styled(Flex)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    div {
       display: none;
    }
        
    @media ${breakpoints.large} {
        width: 260px;
        div {
            display: block;
        }
    }
`

const NavBar = styled(Flex)`
    margin-left: 30px;
    flex: 1;
    justify-content: flex-start;
    
    @media ${breakpoints.large} {
        margin-left: 60px;
    }
`

const InteralLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        text-decoration: none;
    }
`

const DesktopLogo = ({location}) =>
    <LogoContainer>
        <Logo/>
        <div>
            { !_.includes(['/franchise', '/franchise/'], location.pathname) ? <Word/> : <WordRed />}
        </div>
        {/*{location.pathname !== '/franchise' ? <Word/> : <WordRed />}*/}
    </LogoContainer>

const DesktopMenu = ({className, location}) => {

    const isActive = (paths) => _.includes(paths, location.pathname)

    return (
        <Container className={className} dark={isActive(['/franchise', '/franchise/'])}>
            <InteralLink to="/"><DesktopLogo location={location}/></InteralLink>
            <NavBar alignItems='center'>
                <MenuItem to="/" active={isActive('/')}>Home</MenuItem>
                <MenuItem to="/vestigingen" active={isActive(['/vestigingen', '/vestigingen/'])}>Vestigingen</MenuItem>
                <MenuItem to="https://upcarwashbooking.nl" external={true}>Boek nu</MenuItem>
                <MenuItem to="/over-up" active={isActive(['/over-up', '/over-up/'])}>Over UP</MenuItem>
                <MenuItem to="/franchise" active={isActive(['/franchise', '/franchise/'])}>Franchise</MenuItem>
                {/*<MenuItem to="/webshop" active={isActive('/webshop')}>Webshop</MenuItem>*/}
                <MenuItem to="/contact" active={isActive(['/contact', '/contact/'])}>Contact</MenuItem>
            </NavBar>
            <OutboundLink href="https://club.upcarwash.nl/inloggen"><Button color={Button.styles.RED}>UP Club</Button></OutboundLink>
        </Container>
    )
}

export default DesktopMenu

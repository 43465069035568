import React from 'react'
import styled from 'styled-components'
import { Flex, Span } from './UI'
import {OutboundLink} from 'gatsby-plugin-gtag';

const Container = styled(Flex)`
    flex-direction: column;
    cursor: pointer;
`

const Line = styled.div`
    width: 60%;
    height: 3px;
    margin-top: 4px;
    background-color: ${props => props.active ? props.theme.colors.red : 'transparent'};
`

const MenuLink = styled(Span)`
    color: ${props => props.active ? props.theme.colors.black : props.theme.colors.dGrey};
    padding: 0;
    text-decoration: none;
    ${props => props.theme.fontStyle.h3}
`

const ExternalLink = styled(OutboundLink)`
    color: ${props => props.active ? props.theme.colors.black : props.theme.colors.dGrey};
    padding: 0;
    text-decoration: none;
    ${props => props.theme.fontStyle.h3}
`

const MenuItemMobile = ({onClick, to, children, active, external}) =>
    <Container onClick={onClick}>
        {!external ? <MenuLink active={active}>{children}</MenuLink> : <ExternalLink href={to} target="_blank" rel="noopener">{children}</ExternalLink>}
        <Line active={active}/>
    </Container>

export default MenuItemMobile

import React, { useState } from 'react'
import PopOver from './PopOver'
import MobileNav from './MobileNav'
import Hamburger from './Hamburger'
import Logo from '../svg/logo.svg'
import UP from '../svg/up.svg'
import styled from 'styled-components'
import { breakpoints } from '../utils/helper'
import Button from './Button';
import {OutboundLink} from 'gatsby-plugin-gtag';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    
    @media ${breakpoints.tablet} {
        display: none;
    }
`

const ExternalLink = styled(OutboundLink)`
    position: absolute;
    right: 14px;
    color: ${props => props.theme.colors.red};
    ${props => props.theme.fontStyle.link};
    font-weight: bold;
    text-decoration: none;
`

const Spacer = styled('div')`
    width: 26px;
`

const MobileMenu = ({className, location}) => {
    const [isMobileMenuPresent, setIsMobileMenuPresent] = useState(false)

    const presentMobileMenu = (callback) => {
        setIsMobileMenuPresent(true)
        callback()
    }

    return (
        <PopOver
            toggler={(show) =>
                <Container onClick={show} className={`${className}`}>
                    <Hamburger
                        onClick={() => presentMobileMenu(show)}
                        open={isMobileMenuPresent}/>

                    <OutboundLink href="https://club.upcarwash.nl/inloggen"><Button color={Button.styles.RED}>UP Club</Button></OutboundLink>

                </Container>}
            content={hide =>
                <MobileNav
                    location={location}
                    onUpdate={() => setIsMobileMenuPresent(false)}
                    onComplete={hide}/>
            }
        />
    )
}

export default MobileMenu

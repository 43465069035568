import {css} from 'styled-components'

const colors = {
    darkGrey: '#292929',
    cardGrey: '#323232',
    dGrey: '#707170',
    grey: '#DDDDDD',
    lightGrey: '#EEEEEE',
    white: '#FFFFFF',
    red: '#FF3A00',
    black: '#000000'
}

const getStyle = (font, size) =>
    css`
        font-family: ${font.name};
        font-size: ${size}rem;
        font-weight: ${font.weight};
    `

const fonts = {
    PoppinsBold: {
        name: 'Poppins',
        weight: 700,
    },
    PoppinsSemiBold: {
        name: 'Poppins',
        weight: 600
    },
    PoppinsMedium: {
        name: 'Poppins',
        weight: 500
    }
}

const fontStyle = {
    h1: getStyle(fonts.PoppinsBold, 3.75),
    h2: getStyle(fonts.PoppinsBold, 1.75),
    h3: getStyle(fonts.PoppinsBold, 1.125),
    h4: getStyle(fonts.PoppinsBold, 1.125),
    body: getStyle(fonts.PoppinsMedium, 0.875),
    menu: getStyle(fonts.PoppinsBold, 0.875),
    button: getStyle(fonts.PoppinsBold, 0.75),
    link: getStyle(fonts.PoppinsMedium, 0.875),
    footnote: getStyle(fonts.PoppinsMedium, 0.625),
    price: getStyle(fonts.PoppinsBold, 2.25),
}

export const theme = {
    colors,
    fontStyle,
}

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: color 0.2s ease;
    outline: none;
    
    width: 26px;
    height: 21px;
    position: relative;
`

const Line = styled.div`
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: ${props => props.theme.colors.red};
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    
    &:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
    }
    
    &:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
    }
    
    &:nth-child(odd) {
        left: 0px;
        border-radius: 9px 0 0 9px;
    }
    
    &:nth-child(1), &:nth-child(2) {
        top: 0px;
    }
    
    &:nth-child(3), &:nth-child(4) {
        top: 9px;
    }
    
    &:nth-child(5), &:nth-child(6) {
        top: 18px;
    }
    
    &:nth-child(1).open, &:nth-child(6).open {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    
    &:nth-child(2).open, &:nth-child(5).open {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    
    &:nth-child(1).open {
        left: 2px;
        top: 4px;
    }
    
    &:nth-child(2).open {
        left: calc(50% - 2px);
        top: 4px;
    }
    
    &:nth-child(3).open {
        left: -50%;
        opacity: 0;
    }
    
    &:nth-child(4).open {
        left: 100%;
        opacity: 0;
    }
    
    &:nth-child(5).open {
        left: 2px;
        top: 13px;
    }
    
    &:nth-child(6).open {
        left: calc(50% - 2px);
        top: 13px;
    }
}`

const Hamburger = ({onClick, open}) =>
    <Button onClick={onClick}>
        <Line className={open && 'open'}/>
        <Line className={open && 'open'}/>
        <Line className={open && 'open'}/>
        <Line className={open && 'open'}/>
        <Line className={open && 'open'}/>
        <Line className={open && 'open'}/>
    </Button>

Hamburger.propTypes = {
    onClick: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default Hamburger



/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Header from "./header"
import Footer from "./Footer"
import {ThemeProvider} from 'styled-components'
import GlobalStyle from '../styles/globalStyles'
import {theme} from '../styles/defaultTheme'
import axios from 'axios'
import DisclaimerContext from "../context/DisclaimerContext";

const Layout = ({children, location}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `)

    return (
        <DisclaimerContext.Consumer>
            {disclaimer =>
                <ThemeProvider theme={theme}>
                    <GlobalStyle/>
                    <Header siteTitle={data.site.siteMetadata.title} location={location} disclaimer={disclaimer}/>
                    <main>{children}</main>
                    <Footer/>
                </ThemeProvider>
            }
        </DisclaimerContext.Consumer>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

import styled, { css } from 'styled-components'
import { breakpoints } from '../utils/helper'

const FontStyling = css`
    color: ${props => props.color || props.theme.colors.nightA};
    margin: ${props => props.margin || "0"};
    padding: 0;
`

const Widths = css`
    &.text {
        max-width: 420px;
    }
    
    &.med {
        max-width: 520px;   
    }
    
    &.large {
        max-width: 620px;
    }
`

const PageWrapper = styled.div`
    width: ${props => props.width || '100%'};
    max-width: ${props => props.maxWidth || '1170px'};
    padding: 0;
    margin: 0 auto 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media ${breakpoints.desktop} {
        margin: 0 auto 0;
    }
`

const Title = styled.h1`
    ${props => props.theme.fontStyle.h1};
    ${FontStyling}
    line-height: 2rem;
    font-size: 1.875rem;
    
    @media ${breakpoints.tablet} {
        line-height: 3.25rem;
        font-size: 2.75rem;
    }
    
    @media ${breakpoints.desktop} {
        line-height: 4rem;
        font-size: 3.5rem;
    }
    
    @media ${breakpoints.large} {
        line-height: 4.25rem;
        font-size: 3.75rem;
    }
`

const Title2 = styled.h2`
    ${props => props.theme.fontStyle.h2};
    ${FontStyling}
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media ${breakpoints.tablet} {
        font-size: 1.5rem;
        line-height: 2.125rem;
    }
    
    @media ${breakpoints.desktop} {
        font-size: 1.875rem;
    }
`

const Title3 = styled.h3`
    ${props => props.theme.fontStyle.h3};
    ${FontStyling}
    font-size: 1rem;
    
    @media ${breakpoints.tablet} {
        font-size: 1.125rem;
    }
`

const Title4 = styled.h4`
    ${props => props.theme.fontStyle.h4};
    ${FontStyling}
    font-size: 1rem;
    white-space: pre-wrap;
    
    @media ${breakpoints.tablet} {
        font-size: 1.125rem;
    }
`

const Span = styled.span`
    ${props => props.theme.fontStyle.body};
    ${FontStyling}
    white-space: pre-wrap;
    line-height: 1.25rem;
    font-size: 0.75rem;
    
    @media ${breakpoints.tablet} {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`

const Paragraph = styled.p`
    ${props => props.theme.fontStyle.body};
    ${FontStyling}
    white-space: pre-wrap;
    line-height: 1.75rem;
`

const Flex = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: ${props => props.flexDirection || 'row'};
    align-items: ${props => props.alignItems || 'flex-start'};
    justify-content: ${props => props.justifyContent || 'center'};
    width: ${props => props.width || 'auto'};
    height: ${props => props.height || 'auto'};
    margin: ${props => props.margin || '0'};
    padding: ${props => props.padding || '0'};
    overflow: ${props => props.overflow || 'visible'};
    flex-wrap: ${props => props.wrap || 'initial'};
`

const Row = styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px 0;
    
    @media ${breakpoints.tablet} {
        flex-direction: row;
    }
    
    @media ${breakpoints.desktop} {
        padding: 60px 30px 0;
    }    
    
    @media ${breakpoints.large} {
        padding: 90px 0 0;
    }    
`

const LeftSection = styled(Flex)`
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding-right: 0;
    
    @media ${breakpoints.tablet} {
        padding-right: 12px;
        &.text {
            max-width: 320px;
        }
    }
    
    @media ${breakpoints.desktop} {
        ${Widths}
    }
    
    .padded {
        margin: 16px 0 42px;
    }
`

const RightSection = styled(Flex)`
    flex-direction: column;
    width: 100%;
    padding: 24px 0 0;
    
    @media ${breakpoints.tablet} {
        padding: 0 0 0 12px;
        &.text {
            max-width: 320px;
        }
    }
    
    @media ${breakpoints.desktop} {
        ${Widths}
    }
    
    .padded {
        margin: 16px 0 42px;
    }
`

export {
    PageWrapper, Title, Title2, Title3, Title4, Flex, Span, Paragraph, RightSection, LeftSection, Row
}

import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

const StyledButton = styled.button`
    border: 3px solid ${props => props.color.bg};
    height: 40px;
    padding: 0 24px;
    cursor: pointer;
    background-color: ${props => props.inverse ? props.color.bg : 'transparent'};
    color: ${props => props.inverse ? props.theme.colors.white : props.color.bg};
    border-radius: 20px;
    ${props => props.theme.fontStyle.button};
    outline: none;
    
    &:hover {
        background-color: ${props => props.color.bg};
        color: ${props => props.inverse ? props.theme.colors.black : props.color.hover};
    }
`

const Button = ({children, color, inverse, onClick}) => {
    const themeContext = useContext(ThemeContext)

    const getColor = () => {
        switch (color) {
            case Button.styles.WHITE:
                return { bg: themeContext.colors.white, hover: themeContext.colors.black }
            case Button.styles.BLACK:
                return { bg: themeContext.colors.black, hover: themeContext.colors.white }
            case Button.styles.RED:
            default:
                return { bg: themeContext.colors.red, hover: themeContext.colors.white }
        }
    }

    return (
        <StyledButton color={getColor()} onClick={onClick} inverse={inverse}>{children}</StyledButton>
    )
}


export default Button

Button.styles = {
    RED: 'red',
    BLACK: 'black',
    WHITE: 'white',
}

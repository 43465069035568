import React from 'react'
import styled from 'styled-components'
import { Flex } from './UI'
import { Link } from 'gatsby'
import { breakpoints } from '../utils/helper'
import {OutboundLink} from 'gatsby-plugin-gtag';

const Container = styled(Flex)`
    flex-direction: column;
    margin: 0 18px;
    
    @media ${breakpoints.large} {
        margin: 0 24px;
    }
`

const MenuLink = styled(Link)`
    ${props => props.theme.fontStyle.menu};
    color: #000;
    text-decoration: none;
`

const ExternalLink = styled(OutboundLink)`
    ${props => props.theme.fontStyle.menu};
    color: #000;
    text-decoration: none;
`

const Line = styled.div`
    width: 100%;
    height: 3px;
    margin-top: 6px;
    background-color: ${props => props.active ? props.theme.colors.red : 'transparent'};
`

const MenuItem = ({children, to, active, onClick, external}) =>
    <Container onClick={onClick}>
        {!external ? <MenuLink to={to}>{children}</MenuLink> : <ExternalLink href={to} target="_blank" rel="noopener">{children}</ExternalLink>}
        <Line active={active}/>
    </Container>

export default MenuItem

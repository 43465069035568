import React, {useState, forwardRef, useImperativeHandle, useEffect, useRef} from "react"
import Portal from "./Portal"
import styled from 'styled-components'
import {apiURL, breakpoints} from '../utils/helper';
import Close from '../svg/cross.svg'
import axios from 'axios'
import _ from 'lodash'
import FormItem from './FormItem'
import Button from './Button'

const Backdrop = styled.div`
    background-color: rgba(19, 28, 78, 0.3);
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    z-index: 1000;
`

const Wrapper = styled.div`
    flex-direction: column;
    color: ${props => props.theme.colors.nightA};
    overflow: auto;
    background-color: ${props => props.backgroundColor ?? '#fff'};
    outline: none;
    width: 100%;    
    height: 100%;
    border-radius: 0;

    @media ${breakpoints.mobileL} {
        width: 500px;
        height: initial;
        max-height: 75%;
        border-radius: 0.5rem;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    padding: 18px 24px;
`

const Title = styled.h1`
    color: ${props => props.theme.colors.nightA};
    ${props => props.theme.fontStyle.h3};
    width: 100%;
    margin: 6px 14px 0 0;
`

const Body = styled.div`
    width: 100%;
    padding: ${props => props.padding || '24px'};
`

const FormCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Note = styled.span`
    font-size: 0.75rem;
    margin-top: 1.5rem;
    display: block;
`

const Form = () => {
    const btn = useRef()
    const [showError, setShowError] = useState(false)
    const [email, setEmail] = useState(null)
    const [error, setError] = useState('')
    const [statusCode, setStatusCode] = useState('')
    const [sending, setSending] = useState(false)

    useEffect(() => {
        if (!email) {
            setError('Er is geen e-mailadres ingevuld.')
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setError('Het ingevulde e-mailadres is ongeldig')
        } else  {
            setError('')
        }
    }, [email])


    const handleChange = (e) => {
        setShowError(false)
        setEmail(e.target.value)
    }

    const signup = async () => {
        setShowError(true)
        setSending(true)
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            try {

                const res = await axios.post(
                    apiURL('/profiles/resend'),
                    {email: email})
                setShowError(false)
                setSending(false)
                setStatusCode(_.get(res, 'status'))
            } catch ({response}) {
                setError('Er is een fout opgetreden. Neem contact op met onze helpdesk.')
                setStatusCode(_.get(response, 'status', 500))
            }
        }
    }


    return (
        <>
            {
                statusCode >= 200 && statusCode <= 299 ? (
                        <>
                            <span>Verstuurd! Check je mailbox voor je UP Club waspas</span>
                        </>
                    ) : !sending ?
                    <FormCont>
                        <FormItem
                            name="email"
                            label="*Je UP Club e-mailadres"
                            placeholder="info@email.nl"
                            type="text"
                            error={error}
                            toggleError={showError}
                            //value={_.get(formItems, 'email')}
                            onChange={handleChange}
                        />
                        <Button ref={btn} onClick={() => signup()}>Verstuur</Button>
                        <i><Note>Let op! Dit formulier is uitsluitend voor opvragen de digitale UP Club pas.</Note></i>
                    </FormCont>
                    : <span>Bezig met versturen...</span>
            }
        </>
    )
}

const Modal = forwardRef(({title, children}, ref) => {

    const [display, setDisplay] = useState(false)

    useImperativeHandle(
        ref,
        () => {
            return {
                openModal: () => handleOpen(),
                closeModal: () => handleClose(),
            }
        }
    )

    const handleOpen = () => {
        setDisplay(true);
    }

    const handleClose = () => {
       // setStatusCode('')
        setDisplay(false);
    }

    if (display) {
        return (
            <Portal>
                <Backdrop>
                    <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
                        <Header>
                            <Title>{title}</Title>
                            <div style={{'cursor': 'pointer'}} onClick={handleClose}><Close/></div>
                        </Header>
                        <Body>
                            <Form/>
                        </Body>
                    </Wrapper>
                </Backdrop>
            </Portal>
        )
    }

    return null
})

export default Modal
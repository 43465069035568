import _ from 'lodash'

const size = {
    mobileS: '375px',
    mobileM: '414px',
    mobileL: '567px',
    tablet: '768px',
    desktop: '960px',
    large: '1170px'
}

const breakpoints = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    desktop: `(min-width: ${size.desktop})`,
    large: `(min-width: ${size.large})`
}
export const apiURL = (path = '/', env = 'dev') => `https://u0ks5vk330.execute-api.eu-west-1.amazonaws.com/prod/${_.trimStart(path, '/')}`
//export const apiURL = (path = '/', env = 'dev') => `https://api.${_.includes(['dev', 'test'], env) ? `${env}.` : ''}upcarwash.nl/${_.trimStart(path, '/')}`

export const apiResponseStatus = ({ response }) => {
    const { message } = _.get(response, 'data', {})
    const status = _.get(response, 'status')
    if (message) {
        console.warn(`status ${status}: ${message}`)
    }
    return status || 500
}

export {
    breakpoints,
}

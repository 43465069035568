import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    ::-moz-selection { /* Code for Firefox */
      color: #000000;
      background: rgba(255,58,0,0.99);
      opacity: 1;
    }
    
    ::selection {
      color: #000000;
      background: rgba(255,58,0,0.99);
      opacity: 1;
    }
    
    * {
        box-sizing: border-box;
    }

    html, head, body, #root {
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        background-color: #FFFFFF;
        font-family: 'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 1rem;
        color: #000000;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-overflow-scrolling: touch;
        text-rendering: optimizeLegibility;
        
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }
    
    p, span {
        line-height: 1.5;
    }
`
